import React from "react";
import * as styles from "./uberSchrott.module.scss";

const UberSchrott = () => (
  <div className={styles.sectionTop}>
    <div className={styles.bottomBanner}></div>
    <div className={styles.middleBanner}></div>
    <div className={styles.topBanner}>
      <div className={styles.innerContainer}>
        <h1 className={styles.heading}>Über Schrott24</h1>
        <p className={styles.para}>
          "Unsere Mission ist es, eine digitale Plattform aufzubauen, die
          globale Auswirkungen auf den Schrottmarkt hat, die seine ineffizienten
          Prozesse durch Technologie verbessert und eine nachhaltige Zukunft
          schafft."
        </p>
      </div>
    </div>
  </div>
);

export default UberSchrott;
