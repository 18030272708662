import React, { useContext } from "react";
import NavMenu from "../../components/nav-menu/NavMenu";
import Helmet from "react-helmet";
import UberSchrott from "../../components/uber-schrott/UberSchrott";
import AltmetallMarket from "../../components/altmetall-market/AltmetallMarket";
import Video from "../../components/video/Video";
import AboutUs from "../../components/about-us/AboutUs";
import SectionImage from "../../components/section-image/SectionImage";
import MetalIndustry from "../../components/metal-industry/MetalIndustry";
import ContactPersons from "../../components/contact-person/ContactPersons";
import GroupLegal from "../../components/group-legal/GroupLegal";
import SEOComponent from "../../components/Shared/Seo";

import Footer from "../../components/main-page/sections/footer/Footer";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n/i18n";
import DomainContext from "../../context/DomainContext";

import styles from "./index.module.scss";

class UeberunsPage extends React.Component {
  render() {
    const { domain } = this.props;
    return (
      <I18nextProvider i18n={i18n} defaultNS={"default"}>
        <SEOComponent page="ueber-uns" />
        <NavMenu />
        <div className={styles.breadcrumb}>
          <a href="/">Home</a>
          <span className={styles.separator}>/</span>
          <span> Über Uns</span>
        </div>
        <UberSchrott />
        <AltmetallMarket />
        <Video />
        <AboutUs />
        {domain !== "ch" && (
          <SectionImage variation="private" domain={domain} />
        )}
        <MetalIndustry variation="metalIndustry" />
        {<SectionImage variation="recyclers" domain={domain} />}
        {<MetalIndustry variation="scrapDealer" />}
        {domain === "ch" && (
          <SectionImage variation="private" domain={domain} />
        )}
        <ContactPersons />
        {<Footer domain={domain} />}
        <GroupLegal />
      </I18nextProvider>
    );
  }
}

export default function UeberunsWithContext(props) {
  const domain = useContext(DomainContext);

  return <UeberunsPage {...props} domain={domain} />;
}
