import React from "react";
import * as styles from "./altmetallMarket.module.scss";

const AltmetallMarket = () => (
  <div className={styles.sectionText}>
    <div className={styles.innerContainer}>
      <span className={styles.span}>Über uns</span>
      <h2 className={styles.heading}>Wir digitalisieren den Altmetallmarkt!</h2>
      <div className={styles.textContent}>
        <div className={styles.container}>
          <p className={styles.para}>
            Die Kreislaufwirtschaft war noch nie ein wichtigeres Thema für den
            Planeten. Dies steht im Kontext einer ständig wachsenden
            kontinentalen und globalen Nachfrage nach Rohstoffen. Die
            Metallrecyclingbranche spielt eine wichtige Rolle bei der Erreichung
            der Umwelt- und Nachhaltigkeitsziele. Immerhin können Altmetalle zu
            fast hundert Prozent recycelt werden und das beliebig oft.
            Allerdings ist der Status Quo ineffizient und inakzeptabel und trägt
            in hohem Maße zur schlechten Nutzung recycelbarer Metalle bei. Nur
            durch Innovationen in dieser verstaubten Branche kann es zu
            positiven Veränderungen kommen. Deshalb haben wir es uns zur Aufgabe
            gemacht, die gesamte Branche komplett umzukrempeln. So holen wir
            eine großteils analoge Branche ins digitale Zeitalter und bieten
            unseren Kunden zeitgemäße Prozesse an. Die Schrott24-Plattform
            revolutioniert die Metallrecyclingbranche, indem sie den Markt
            transparent, digital und effizient macht und Prozesse vereinfacht
            und automatisiert. Die Plattform basiert auf modernsten maschinellen
            Lern- und Datenanalysen, die es ermöglichen, Angebot und Anfrage von
            Altmetallen miteinander zu matchen. Diese Innovation bereichert
            damit alle Teilnehmer der Wertschöpfungskette. Durch die Bündelung
            von Mengen vieler Lieferanten können wir Preisvorteile erzielen und
            weitergeben. Schrotthändlern, Recyclern und Werken helfen wir, ihre
            Auslastung zu steigern und neue Lieferanten zu gewinnen.
            Mittlerweile haben wir über 400 Logistik- und Recyclingpartner
            europaweit, sowie über 80 Anlieferstellen für Altmetall in
            Deutschland und Österreich. Schrott24 wurde 2016 in Graz von
            Alexander Schlick und Jan Pannenbäcker gegründet. Inzwischen hat
            sich unser Unternehmen zur größten digitalen Handelsplattform für
            Altmetall etabliert.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default AltmetallMarket;
