import React from "react";
import * as styles from "./metalIndustry.module.scss";

const labels = {
  metalIndustry: {
    subtitle: "Schrott24 PRO",
    title: "Metallverarbeitende Industrie",
    bgText: '"Wir matchen digital und effizient Angebot mit Nachfrage"',
    para: (
      <>
        <p className={styles.para}>
          Als größte europäische Handelsplattform für Altmetalle arbeiten wir
          mit der modernen Technologie unserer Plattform, die es uns ermöglicht,
          dynamisch auf Angebot und Nachfrage der Altmetalle in unserem Netzwerk
          aus Schrotthändlern und Werken zu reagieren. Somit können wir
          insbesondere für die metallverarbeitende Industrie den besten Absatz
          für Altmetall erzielen.
        </p>
        <p className={styles.para}>
          Zudem setzen wir uns mit jeder Anfrage individuell auseinander, um den
          Erlös und den Recyclingprozess zu optimieren. Für unsere Kunden wird
          dabei die operative Abwicklung durch unsere digitalen Tools einfacher,
          effizienter und transparenter gestaltet.
        </p>
        <p className={styles.para}>
          Dabei ist Schrott24 PRO lizenzierter Altmetallhändler und der
          Vertrags- und Ansprechpartner, wenn es um Angebot, Transportabwicklung
          und Auszahlung geht. Wir bieten unseren Kunden volle Sicherheit und
          Transparenz für ihren Altmetallverkauf und stellen einen unserer
          Logistikpartner als Ansprechpartner vor Ort zur Verfügung.
        </p>
      </>
    ),
    buttonText: "Zu Schrott24 Pro",
  },
  scrapDealer: {
    subtitle: "SCHROTT24 PARTNER",
    title: "Schrotthändler",
    bgText:
      '"Digitalisierung als Chance für größere Reichweite und Optimierung interner Prozesse nutzen."',
    para: (
      <>
        <p className={styles.para}>
          Wir unterstützen unsere Partner-Schrotthändler beim Ein- und Verkauf
          von Altmetall und helfen dabei, die Branche ins digitale Zeitalter zu
          führen.
        </p>
        <p className={styles.para}>
          Durch unsere große online Reichweite ermöglichen wir unseren Partnern
          Zugang zu mehr Material und neuen Entfallstellen. Dabei übernehmen wir
          die Rolle des Lieferanten und kümmern uns um die Abwicklung.
        </p>
        <p className={styles.para}>
          Wir sind ebenfalls in der Position, den besten Abnehmer für das
          Altmetall zu finden. Durch unser großes Netzwerk können wir Zugang zu
          Werkspreisen und bessere Erlöse für Vollladungen ermöglichen.
          Profitieren Sie von geringeren Lagerzeiten und reduziertem Kursrisiko!
        </p>
        <p className={styles.para}>
          Zusätzlich bieten wir moderne Tools, welche die internen Prozesse
          erheblich optimieren und somit Zeit und Ressourcen bei den Ver- und
          Ankaufsprozessen von Altmetall sparen, an.
        </p>
      </>
    ),
    buttonText: "So werden Sie Schrott24-Partner",
  },
};

const MetalIndustry = ({ variation }) => {
  const finalLabels = labels[variation];

  return (
    <div className={styles.sectionImageEven}>
      <div className={styles.content}>
        <div className={styles.textContent}>
          <span className={styles.row1}>{finalLabels.subtitle}</span>{" "}
          <span className={styles.row2}>{finalLabels.title}</span>
          <div className={styles.mobileTestImage}>
            {variation === "metalIndustry" ? (
              <div
                id="sectionImage2"
                className={styles.sectionImageContainer}
              ></div>
            ) : (
              <div
                id="scrapDealer"
                className={styles.scrapDealerImageContainer}
              ></div>
            )}
            <div className={styles.textBackgroud} id={variation}>
              <span className={styles.span}>{finalLabels.bgText}</span>
            </div>
          </div>
          {finalLabels.para}
          <a className={styles.button} href="/pro">
            {finalLabels.buttonText}
          </a>
        </div>
        <div className={styles.imageContent}>
          {variation === "metalIndustry" ? (
            <div
              id="sectionImage2"
              className={styles.sectionImageContainer}
            ></div>
          ) : (
            <div
              id="scrapDealer"
              className={styles.scrapDealerImageContainer}
            ></div>
          )}
          <div className={styles.textBackgroudShort}>
            <span className={styles.span}>{finalLabels.bgText}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetalIndustry;
