import React from "react";
import * as styles from "./video.module.scss";

const Video = () => (
  <div className={styles.sectionVideo}>
    <div className={styles.sectionGreyBackground}>
      <div className={styles.lightGrey}></div>
      <div className={styles.darkGrey}></div>
    </div>
    <div className={styles.diagonalBackground}>
      <div className={styles.diagonalBackgroundContainer}>
        <div className={styles.diagonalDarkGrey}></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <iframe
      title="About us video"
      className={styles.iFrame}
      width="380"
      height="215"
      src="https://www.youtube.com/embed/76CdWnK6Q60"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen=""
    ></iframe>
    <iframe
      title="About us video"
      className={styles.iFrame}
      width="380"
      height="215"
      src="https://www.youtube.com/embed/MY-CROlgIu8"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen=""
    ></iframe>
  </div>
);

export default Video;
