import React from "react";
import * as styles from "./sectionImage.module.scss";

const labels = {
  ch: {
    private: {
      quote:
        '"Mehr Transparenz und Anwenderfreundlichkeit durch digitalen Fortschritt"',
      subtitle: "ONLINE SHOP",
      title: "Gewerbe und Privat",
      bgText:
        '"Mehr Transparenz und Anwenderfreundlichkeit durch digitalen Fortschritt"',
      para: (
        <>
          <p className={styles.para}>
            Um den Altmetallhandel transparenter zu gestalten, veröffentlichen
            wir nicht nur unsere tagesaktuellen Schrottpreise auf unserer
            Onlineplattform, sondern gewährleisten unseren Kunden auch die
            genaue Dokumentation der Qualitätsprüfung und Wiegung ihrer
            verkauften Altmetalle.
          </p>
          <p className={styles.para}>
            Betriebe und Privatpersonen können so den exakten Schrottpreis für
            kleinere Altmetallmengen anhand des Standorts und der
            Transportoption (im Checkout) berechnen und gleich direkt über den
            Webshop verkaufen.
          </p>
          <p className={styles.para}>
            Mit über 80 Standorten gibt es in Deutschland und Österreich
            flächendeckend Anlieferstellen für Altmetall.
          </p>
          <p className={styles.para}>
            Unser Service umfasst die gesamte Abwicklung vom Verkauf, über die
            Organisation des Transports, bis hin zur Auszahlung.
          </p>
        </>
      ),
      buttonText: "Aktuelle Schrottpreise ansehen",
    },
    recyclers: {
      subtitle: "Schrott24 PRO",
      title: "Recycler und Werke",
      bgText:
        '"Schnelle und flexible Verfügbarkeit von geprüftem Sekundärrohstoff"',
      para: (
        <>
          <p className={styles.para}>
            Metallverarbeitenden oder recycelnden Unternehmen bieten wir
            verschiedenste Altmetallsorten sowie spezielle Legierungen, die auf
            die Werksqualität vorbereitet sind, an.
          </p>
          <p className={styles.para}>
            Als Schrott24 PRO verfügen wir über ein großes Netzwerk aus
            Lieferanten und haben dadurch einen enormen Zugang zu geprüftem und
            qualitativ hochwertigem Altmetall. Die moderne Technologie unserer
            digitalen Plattform ermöglicht eine rasche Qualifizierung und kann
            somit eine schnelle und flexible Verfügbarkeit der sekundären
            Rohstoffe für Unternehmen garantieren.
          </p>
          <p className={styles.para}>
            Die über 10 Jahre lange Erfahrung in dieser Branche hat uns die
            Herausforderungen von Werken und Recyclern bewusst gemacht, weswegen
            wir uns auch der Verantwortung bewusst sind, diese nur mit geprüftem
            Altmetall zu beliefern. Schrott24 PRO ist unabhängig und ermöglicht
            einen anonymen An- und Verkauf von Altmetall.
          </p>
        </>
      ),
      buttonText: "Mehr dazu",
    },
  },
  common: {
    private: {
      subtitle: "ONLINE SHOP",
      title: "Gewerbe und Privat",
      bgText:
        '"Mehr Transparenz und Anwenderfreundlichkeit durch digitalen Fortschritt"',
      para: (
        <>
          <p className={styles.para}>
            Um den Altmetallhandel transparenter zu gestalten, veröffentlichen
            wir nicht nur unsere tagesaktuellen Schrottpreise auf unserer
            Onlineplattform, sondern gewährleisten unseren Kunden auch die
            genaue Dokumentation der Qualitätsprüfung und Wiegung ihrer
            verkauften Altmetalle.
          </p>
          <p className={styles.para}>
            Betriebe und Privatpersonen können so den exakten Schrottpreis für
            kleinere Altmetallmengen anhand des Standorts und der
            Transportoption (im Checkout) berechnen und gleich direkt über den
            Webshop verkaufen.
          </p>
          <p className={styles.para}>
            Mit über 80 Standorten gibt es in Deutschland und Österreich
            flächendeckend Anlieferstellen für Altmetall.
          </p>
          <p className={styles.para}>
            Unser Service umfasst die gesamte Abwicklung vom Verkauf, über die
            Organisation des Transports, bis hin zur Auszahlung.
          </p>
        </>
      ),
      buttonText: "Mehr dazu",
    },
    recyclers: {
      subtitle: "Schrott24 PRO",
      title: "Recycler und Werke",
      bgText:
        '"Schnelle und flexible Verfügbarkeit von geprüftem Sekundärrohstoff"',
      para: (
        <>
          <p className={styles.para}>
            Metallverarbeitenden oder recycelnden Unternehmen bieten wir
            verschiedenste Altmetallsorten sowie spezielle Legierungen, die auf
            die Werksqualität vorbereitet sind, an.
          </p>
          <p className={styles.para}>
            Als Schrott24 PRO verfügen wir über ein großes Netzwerk aus
            Lieferanten und haben dadurch einen enormen Zugang zu geprüftem und
            qualitativ hochwertigem Altmetall. Die moderne Technologie unserer
            digitalen Plattform ermöglicht eine rasche Qualifizierung und kann
            somit eine schnelle und flexible Verfügbarkeit der sekundären
            Rohstoffe für Unternehmen garantieren.
          </p>
          <p className={styles.para}>
            Die über 10 Jahre lange Erfahrung in dieser Branche hat uns die
            Herausforderungen von Werken und Recyclern bewusst gemacht, weswegen
            wir uns auch der Verantwortung bewusst sind, diese nur mit geprüftem
            Altmetall zu beliefern. Schrott24 PRO ist unabhängig und ermöglicht
            einen anonymen An- und Verkauf von Altmetall.
          </p>
        </>
      ),
      buttonText: "Mehr dazu",
    },
  },
};

const SectionImage = ({ domain, variation }) => {
  const finalLabels = {
    ...labels["common"],
    ...(labels[domain] || {}),
  }[variation];

  return (
    <div className={styles.sectionImage}>
      <div className={styles.content}>
        <div className={styles.imageContent}>
          {variation === "private" ? (
            <div id="private" className={styles.privateImageContainer}></div>
          ) : (
            <div
              id="sectionImage3"
              className={styles.sectionImageContainer}
            ></div>
          )}
          <div className={styles.textBackgroundShort}>
            <span className={styles.span}>{finalLabels.bgText}</span>
          </div>
        </div>
        <div className={styles.textContent}>
          <span className={styles.row1}>{finalLabels.subtitle}</span>{" "}
          <span className={styles.row2}>{finalLabels.title}</span>
          <div className={styles.mobileImage}>
            {variation === "private" ? (
              <div id="private" className={styles.privateImageContainer}></div>
            ) : (
              <div
                id="sectionImage3"
                className={styles.sectionImageContainer}
              ></div>
            )}
            <div className={styles.textBackground} id={variation}>
              <span className={styles.span}>{finalLabels.bgText}</span>
            </div>
          </div>
          {finalLabels.para}
          <a className={styles.button} href="/altmetall-kaufen">
            {finalLabels.buttonText}
          </a>
        </div>
      </div>
    </div>
  );
};

export default SectionImage;
