import React from "react";
import * as styles from "./aboutUs.module.scss";

const AboutUs = () => (
  <div className={styles.sectionBlue}>
    <span className={styles.row1}>Über uns</span>{" "}
    <span className={styles.row2}>
      Wir entwickeln eine Plattform, um die Sekundärrohstoff-Wirtschaft
      miteinander zu verbinden
    </span>
    <span className={styles.row3}>
      Wir revolutionieren und digitalisieren die gesamte Altmetallbranche und
      davon profitiert die gesamte Kreislaufwirtschaft.
    </span>
    <div className={styles.backgroundImage}></div>
  </div>
);

export default AboutUs;
