import React from "react";
import * as styles from "./contactPersons.module.scss";

import image1 from "../../images/jan.png";
import image2 from "../../images/alexander.png";
import image3 from "../../images/andreas.png";
import image4 from "../../images/jon.png";
import image5 from "../../images/georg.png";
import image6 from "../../images/harvey.png";
import image7 from "../../images/team.png";
import linkedInImage from "../../images/linkedin-yellow.png";

const data = [
  {
    URL: "https://www.linkedin.com/in/janpannenbaecker/",
    image: image1,
    name: "Jan Pannenbäcker",
    position: "Co-founder & Managing Director",
  },
  {
    URL: "https://www.linkedin.com/in/alexander-schlick-47706986/",
    image: image2,
    name: "Alexander Schlick",
    position: "Co-founder & Managing Director",
  },
  {
    URL: "https://www.linkedin.com/in/andreasfruth/",
    image: image3,
    name: "Dr. Andreas Fruth",
    position: "CTO",
  },
  {
    URL: "https://www.linkedin.com/in/jonathan-sivak-88bb1715/",
    image: image4,
    name: "Jon Sivak",
    position: "Head Of Product",
  },
  {
    URL: "https://www.linkedin.com/in/georg-ruderer-6bb198136/",
    image: image5,
    name: "Georg Ruderer",
    position: "Senior Sales Manager",
  },
];

const ContactPersons = () => (
  <div className={styles.sectionPictures}>
    <div className={styles.content}>
      <div className={styles.head}>
        <span className={styles.row1}>Über Schrott24</span>{" "}
        <span className={styles.row2}>Ansprechpartner</span>
      </div>
      <div className={styles.peopleContainer}>
        {data.map(item => (
          <div className={styles.person}>
            <img className={styles.image1} src={item.image} alt="image1" />
            <div className={styles.nameContainer}>
              <span className={styles.name}>{item.name}</span>
              <a
                className={styles.button}
                href={item.URL}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={styles.imageLinked}
                  src={linkedInImage}
                  alt="linkedinImage"
                />
              </a>
            </div>
            <span className={styles.position}>{item.position}</span>
          </div>
        ))}
        <div className={styles.person}>
          <img className={styles.image1} src={image7} alt="image7" />
          <div className={styles.nameContainer}>
            <span className={styles.name}>You</span>
          </div>
          <a
            className={styles.button}
            href="https://www.schrott24.de/faq/karriere"
          >
            Werde Teil von Schrott24
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default ContactPersons;
